import React, { useState, useEffect } from 'react';
import '../App.css';
import desktopImage from '../assets/back-pc.jpg';
import mobileImage from '../assets/back-mobile.jpg';
import psn from '../assets/psn.png';
import psnmb from '../assets/psnmb.png';
import num from '../assets/2020.png';
import pivi from '../assets/pivi.png';
import quo from '../assets/quo.png';
import quom from '../assets/quom.png';

import backgroundmusic from '../assets/backmusic/backmusic2.mp3';

import rf from '../assets/rf.jpg';
import mrwimal2 from '../assets/poson.png';
import { Button} from 'reactstrap';
import { Link, withRouter,NavLink } from 'react-router-dom';
import LazyLoad from "react-lazyload";


const Startpage = () => {
    
    
    const backmusic=React.createRef();
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const imageUrl = windowWidth >= 650 ? desktopImage : mobileImage;
    const headerurl = windowWidth >= 650 ? psn : psnmb;

    const widthbtn = windowWidth >= 650 ? '80%' : '55%';
    const heightquot = windowWidth >= 650 ? '88%' : '90%';


    const quot = windowWidth >= 650 ? quo : quom;

    useEffect(() => {
       
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleWindowResize);
      
        //backmusic.current.play();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
            
        }
    }, []);

    
    return (
        
        <div className="App" style={{backgroundImage: `url(${imageUrl})` }}>
             <audio ref={backmusic} src={backgroundmusic} autoPlay></audio>
            
            <div className="App-content" style={{backgroundColor:"",height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center'}}>
                   
                <div style={{backgroundColor:"",height:'40vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:"column"}}>
                        <img src={headerurl} height='50%'/>
                        <img src={num} height='30%'/>
                </div>

                <div style={{backgroundColor:"",height:'40vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:"row"}}>
                    <div style={{backgroundColor:"",height:'100%',width:'20vw',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <img src={rf} width='50%' style={{borderRadius:70,marginRight:'10vw'}}/>
                    </div>
                    <div style={{backgroundColor:"",height:'100%',width:'40vw',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <NavLink to="/main" style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <img src={pivi} height={widthbtn}/>
                    </NavLink>
                        
                    </div>
                    <div style={{backgroundColor:"",height:'100%',width:'20vw',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img src={mrwimal2} width='130%' style={{borderRadius:70,marginLeft:'10vw'}}/>
                    </div>
                       
                </div>
                <div style={{backgroundColor:"",height:'15vh',width:'100vw',display:'flex',justifyContent:'center'}}>

                    <img src={quot} height={heightquot}/> 

                </div>
                <div style={{backgroundColor:"",height:'1vh',width:'100vw',display:'flex',justifyContent:'center',textAlignL:'center',flexDirection:'column'}}>

                   <p style={{fontSize:'1vh',color:'white',width:'100vw',justifyContent:'right',alignItems:'right',textAlign:'center',height:'1vh'}}>Background song: LOWTHURU GUNA MAHIME, 
                   singing-IakminiUdawaththa, lyrics-Kaushika Gayan Fonseka, melody- Darshana Wickramathunga</p>
               
                  
                </div>

            </div>
        </div>
        
    );
};

export default Startpage;