import React, { useState, useEffect } from 'react';
import '../App.css';
import desktopImage from '../assets/bbackpc.jpg';
import mobileImage from '../assets/backmb.jpg';
import Scrollbar from "react-scrollbars-custom";
import rf from '../assets/rf.jpg';
import pchead from '../assets/mainpch.png';
import mbhead from '../assets/mainmb.png';
import btmtpc from '../assets/btmtpc.png';
import btmtmb from '../assets/btmtmb.png';
import { Link, withRouter,NavLink } from 'react-router-dom'

import kudu1 from '../assets/kudu1.jpg';
import kudu2 from '../assets/kudu2.jpg';
import kudu3 from '../assets/kudu3.jpg';

import v1 from '../assets/kudu1.mp4';
import v2 from '../assets/kudu2.mp4';
import v3 from '../assets/kudu3.mp4';

import backgroundmusic from '../assets/backmusic/backmusic2.mp3';
import LazyLoad from "react-lazyload";

import { Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';


const Startpage = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const imageUrl = windowWidth >= 650 ? desktopImage : mobileImage;
    const hwidth = windowWidth >= 650 ? '100%' : '70%';
    const header = windowWidth >= 650 ? pchead : mbhead;
    const logowidth = windowWidth >= 650 ? '100%' : '20%';

    const bottmtxt = windowWidth >= 650 ? btmtpc : btmtmb;
    const backmusic=React.createRef();
    useEffect(() => {
        
       
           // backmusic.current.play();
     
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);
    
    return (
        <div className="App" style={{backgroundImage: `url(${imageUrl})` }}  >
             <audio ref={backmusic} src={backgroundmusic} autoPlay></audio>
            
            <div className="App-content" style={{backgroundColor:"",height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                <Row style={{display:'flex',justifyContent:'center',alignItems:'center',height:'25%',width:'100%'}}>
                    <Col sm="1" style={{textAlign:'center'}}>
                    <NavLink to="/" style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <img src={rf} width={logowidth} style={{borderRadius:70}}/> 
                  
                    </NavLink>
                         </Col>
                    <Col sm="9" style={{textAlign:'center'}}>
                        <img src={header} width={hwidth}/> 
                    </Col>
                    <Col sm="1" style={{textAlign:'center'}}>
                        
                    </Col>

                </Row>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'60%',width:'100vw'}}>
                    <Scrollbar  disableTracksWidthCompensation  style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%',width:"100%"}}>
                    <Row style={{display:'flex',justifyContent:'center',alignItems:'center',height:'95%',width:'100%'}}>

                    <Col sm="3" style={{margin:'2%'}}>
                    <div  style={{height:'55vh',backgroundColor:'',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',margin:'2%'}}>

                        <div style={{height:'80%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img src={kudu1} width='100%' style={{borderTopLeftRadius:20,borderTopRightRadius:20,borderBottomRightRadius:10,borderBottomLeftRadius:10}}/>
                        </div>
                        <NavLink 
                        to={{
                            pathname:'/poson-kudu',
                            state: { url:v1
                        }

                        }} 
                        style={{width:'100%',height:'15%',display:'flex',justifyContent:'center',backgroundColor:'#FF5722',borderRadius:10}}>
                            <Button style={{width:'100%',fontSize:'4vh',backgroundColor:'#FF5722',borderColor:'#FF5722',height:'100%',borderRadius:20}}>පහන් කූඩුව 1</Button>
                        </NavLink>

                    </div>

                    </Col>
                    <Col sm="3" style={{margin:'2%'}}>
                    <div  style={{height:'55vh',backgroundColor:'',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',margin:'2%'}}>

                        <div style={{height:'80%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img src={kudu2} width='100%' style={{borderTopLeftRadius:20,borderTopRightRadius:20,borderBottomRightRadius:10,borderBottomLeftRadius:10}}/>
                        </div>
                        <NavLink 
                        to={{
                            pathname:'/poson-kudu',
                            state: { url:v2
                        }
                        

                        }} 
                        style={{width:'100%',height:'15%',display:'flex',justifyContent:'center',backgroundColor:'#FF5722',borderRadius:10}}>
                            <Button style={{width:'100%',fontSize:'4vh',backgroundColor:'#FF5722',borderColor:'#FF5722',height:'100%',borderRadius:20}}>පහන් කූඩුව 2</Button>
                        </NavLink>

                    </div>
                    </Col>
                    <Col sm="3" style={{margin:'2%'}}>
                    <div  style={{height:'55vh',backgroundColor:'',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',margin:'2%'}}>

                        <div style={{height:'80%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img src={kudu3} width='100%' style={{borderTopLeftRadius:20,borderTopRightRadius:20,borderBottomRightRadius:10,borderBottomLeftRadius:10}}/>
                        </div>
                        <NavLink 
                        to={{
                            pathname:'/poson-kudu',
                            state: { url:v3
                        }
                        

                        }} 
                        style={{width:'100%',height:'15%',display:'flex',justifyContent:'center',backgroundColor:'#FF5722',borderRadius:10}}>
                            <Button style={{width:'100%',fontSize:'4vh',backgroundColor:'#FF5722',borderColor:'#FF5722',height:'100%',borderRadius:20}}>පහන් කූඩුව 3</Button>
                        </NavLink>

                        </div>


                    </Col>
                    
                </Row>
                
                    </Scrollbar>
                </div>

                <Row style={{display:'flex',justifyContent:'center',alignItems:'center',height:'15%',width:'100%'}}>     
                        <img src={bottmtxt} width='50%'/>
                </Row>
           

            </div>
        </div>
    );
};

export default Startpage;