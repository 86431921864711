import React, { useState, useEffect } from 'react';
import '../App.css';

import ReactPlayer from 'react-player'

// import { Player, ControlBar,Shortcut  } from 'video-react';
// import "../../node_modules/video-react/dist/video-react.css"
import desktopImage from '../assets/bbackpc.jpg';
import mobileImage from '../assets/backmb.jpg';
import Scrollbar from "react-scrollbars-custom";
import rf from '../assets/rf.jpg';
import pchead from '../assets/mainpch.png';
import mbhead from '../assets/mainmb.png';
import { Link, withRouter,NavLink } from 'react-router-dom'
import backgroundmusic from '../assets/backmusic/kudusong.mp3';
import LazyLoad from "react-lazyload";


import { Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';


const Startpage = (props) => {
    const backmusic=React.createRef();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const imageUrl = windowWidth >= 650 ? desktopImage : mobileImage;
    const logowidth = windowWidth >= 650 ? '100%' : '20%';
    const theight = windowWidth >= 650 ? '80%' : '60%';
    
    const bbtmmargin = windowWidth >= 650 ? '10vh' : '15vh';
    const header = windowWidth >= 650 ? pchead : mbhead;
    useEffect(() => {
        //backmusic.current.play();
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);
    
    // console.log('url',props.location.state.url)
    return (
        
        <div className="App" style={{backgroundImage: `url(${imageUrl})` }}>
             <audio ref={backmusic} src={backgroundmusic} autoPlay></audio>
            <div className="App-content" style={{backgroundColor:"",height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Row style={{display:'flex',justifyContent:'center',alignItems:'center',height:'10vh',width:'100%',marginBottom:bbtmmargin}}>
                    <Col sm="1" style={{textAlign:'center',height:'100%',width:'100%'}}>
                    <NavLink to="/main" style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img src={rf} width={logowidth} style={{borderRadius:70}}/> 
                    </NavLink>
                       
                    </Col>
                    <Col sm="9" style={{textAlign:'center'}}>
                        <img src={header} width='100%'/> 
                    </Col>
                    <Col sm="1" style={{textAlign:'center'}}>
                        
                    </Col>

            </Row>
           
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:theight,width:'100%'}}>
             <ReactPlayer
                className='react-player'
                url={props.location.state.url}
                loop={true}
                playing	={true}
                controls={false}
				muted={true}
                fluid={"false"}
                width="100%"
                height='100%'         
                />
        </div>
       
                   
            
          
           

            </div>
        </div>
    );
};

export default Startpage;